import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import Title from "../components/UI/Title/Title";
import { Button } from "../components/UI/Button/Button";
import resume from "../assets/pdfs/Suraj_Resume.pdf";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    flexGrow: 1,
    marginBottom: 70,
    marginTop: 10,
  },
  container: {
    position: "relative",
    overflow: "hidden",
    width: "100%",
    height: "100%",
    paddingTop: "56.25%",
  },
  iframe: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: "100%",
    height: "100%",
  },
});

function Resume() {
  const classes = useStyles();
  return (
    <Grid container>
      <Grid item>
        <Title title="Eye's On My Resume" />
      </Grid>
      <Grid container spacing={4} className={classes.root}>
        <Container className={classes.container}>
          <iframe
            src={resume}
            title="resume"
            className={classes.iframe}
          ></iframe>
        </Container>

        <a href={resume} target="blank">
          {" "}
          <Button active style={{ marginTop: 20, fontWeight: "bold" }}>
            Full
          </Button>
        </a>
      </Grid>
    </Grid>
  );
}

export default Resume;
