import React, { useState } from "react";
import axios from "axios";
import { Grid, Card, Typography, Link as MuiLink } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { HashLoader } from "react-spinners";
import { css } from "@emotion/core";
import {
  SiHashnode,
  SiTwitter,
  SiLinkedin,
  SiGithub,
  SiMedium,
} from "react-icons/si";
import { AiFillMail } from "react-icons/ai";

import { profile } from "../data";
import Title from "../components/UI/Title/Title";
import Input from "../components/UI/Input/Input";
import { Button } from "../components/UI/Button/Button";
import { useForm, Form } from "../components/Form/Form";
import Modal from "../components/UI/Modal/Modal";
import configs from "../config";

const initialValues = {
  name: "",
  email: "",
  subject: "",
  message: "",
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    flexGrow: 1,
    marginBottom: 30,
  },
  title: {
    textAlign: "center",
    marginBottom: 10,
    fontSize: 20,
    color: "#ffa400",
  },
  card: {
    marginTop: 30,
    color: "black",
  },
  socialMedia: {
    marginTop: 20,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  socialHeading: {
    fontSize: "1.8rem",
    marginBottom: "20px",
    color: "#ffa400",
    textAlign: "center",
  },
  socialItem: {
    display: "flex",
    alignItems: "center",
    marginBottom: "30px",
  },
  icon: {
    fontSize: "2rem",
    width: "2rem",
    height: "2rem",
    marginRight: "10px",
    "&:hover": {
      color: "#ff7400",
    },
  },
  linkText: {
    color: "white",
    textDecoration: "none",
    "&:hover": {
      color: "#ff7400",
      textDecoration: "underline",
    },
  },
}));

const override = css`
  display: block;
  margin: 0 auto;
  border-color: white;
  margin-top: 185px;
  text-align: center;
`;

export default function ContactForm() {
  const classes = useStyles();
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("name" in fieldValues) {
      temp.name = fieldValues.name ? "" : "This field is required.";
    }

    if ("email" in fieldValues) {
      temp.email = /\S+@\S+\.\S+/.test(fieldValues.email)
        ? ""
        : "Email is not valid.";
    }

    if ("message" in fieldValues) {
      temp.message = fieldValues.message ? "" : "This field is required.";
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, errors, setErrors, handleInputChange, resetForm } = useForm(
    initialValues,
    true,
    validate
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      const url = configs.BASE_URL + "contact/send-mail/";
      setLoading(true);
      axios
        .post(url, values, {
          headers: {
            "content-type": "application/json",
          },
        })
        .then((res) => {
          setData(res.data);
          setModalIsOpen(true);
          setLoading(false);
          resetForm();
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const handleClose = () => {
    setModalIsOpen(false);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Modal handleClose={handleClose} modalIsOpen={modalIsOpen} data={data} />
      {!loading ? (
        <Grid container className={classes.root}>
          <Grid item xs={12} md={8}>
            <Grid item style={{ marginTop: "10px" }}>
              <Title title="CONTACT ME" />
            </Grid>
            <Grid item xs={12}>
              <Card elevation={4} className={classes.card}>
                <Input
                  name="name"
                  label="Name"
                  value={values.name}
                  onChange={handleInputChange}
                  error={errors.name}
                  required={true}
                />
                <Input
                  label="Email"
                  name="email"
                  value={values.email}
                  onChange={handleInputChange}
                  error={errors.email}
                  required={true}
                />
                <Input
                  label="Subject"
                  name="subject"
                  value={values.subject}
                  onChange={handleInputChange}
                  error={errors.subject}
                />
                <Input
                  label="Message"
                  name="message"
                  value={values.message}
                  onChange={handleInputChange}
                  error={errors.message}
                  required={true}
                  multiline={true}
                  rows={6}
                />
                <div style={{ marginLeft: 5 }}>
                  <Button>SUBMIT</Button>
                </div>
              </Card>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4} className={classes.socialMedia}>
            <Typography className={classes.socialHeading}>
              Social Links
            </Typography>
            <div className={classes.socialItem}>
              <AiFillMail className={classes.icon} />
              <MuiLink
                href={`mailto:${profile.emails}`}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.linkText}
              >
                Email
              </MuiLink>
            </div>
            <div className={classes.socialItem}>
              <SiLinkedin className={classes.icon} />
              <MuiLink
                href="https://www.linkedin.com/in/surajk66/"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.linkText}
              >
                Suraj Karki
              </MuiLink>
            </div>
            <div className={classes.socialItem}>
              <SiGithub className={classes.icon} />
              <MuiLink
                href="https://www.github.com/surajkarki66"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.linkText}
              >
                surajkarki66
              </MuiLink>
            </div>
            <div className={classes.socialItem}>
              <SiTwitter className={classes.icon} />
              <MuiLink
                href="https://x.com/surajka16234107"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.linkText}
              >
                @surajka16234107
              </MuiLink>
            </div>
            <div className={classes.socialItem}>
              <SiHashnode className={classes.icon} />
              <MuiLink
                href="https://savvysuraj.hashnode.dev/"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.linkText}
              >
                @surajkarki
              </MuiLink>
            </div>
            <div className={classes.socialItem}>
              <SiMedium className={classes.icon} />
              <MuiLink
                href="https://medium.com/@surajkarki"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.linkText}
              >
                @surajkarki
              </MuiLink>
            </div>
          </Grid>
        </Grid>
      ) : (
        <HashLoader css={override} size={140} color={"white"} />
      )}
    </Form>
  );
}
