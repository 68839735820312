import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { css } from "@emotion/core";
import { HashLoader } from "react-spinners";

import { GET_USER_ARTICLES } from "../queries/blogQueries";
import Title from "../components/UI/Title/Title";
import BlogCard from "../components/BlogCard/BlogCard";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    flexGrow: 1,
    marginBottom: 0,
    marginTop: 0,
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    margin: theme.spacing(1),
    "&:hover": {
      color: "#ffa400",
    },
  },
}));

const override = css`
  display: block;
  margin: 0 auto;
  border-color: white;
  margin-top: 185px;
  text-align: center;
`;

const Blog = () => {
  const classes = useStyles();
  const [hashnodeArticles, setHashNodeArticles] = useState([]);
  const [loading, setLoading] = useState(true);

  async function getHashnodeBlogs(query, variables = {}) {
    const data = await fetch("https://gql.hashnode.com/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query,
        variables,
      }),
    });

    return data.json();
  }

  useEffect(() => {
    async function fetchBlogs() {
      try {
        const blogs = await getHashnodeBlogs(GET_USER_ARTICLES);
        return blogs;
      } catch (error) {
        throw error;
      }
    }
    fetchBlogs()
      .then((res) => {
        const hashnodeRes = res;
        setHashNodeArticles(hashnodeRes.data.publication.posts.edges);
        setLoading(false);
      })
      .catch((error) => {
        setHashNodeArticles([]);
        setLoading(false);
      });
  }, []);

  return (
    <>
      {!loading ? (
        <>
          <Grid container>
            <Grid item>
              <Title title="My Blogs" />
            </Grid>
            <Grid container spacing={2} className={classes.root}>
              {hashnodeArticles.map((article) => (
                <Grid xs={12} item key={article.node.slug}>
                  <BlogCard blog={article} type="hashnode" />
                </Grid>
              ))}
            </Grid>
          </Grid>
          <div className={classes.buttonContainer}>
          </div>
        </>
      ) : (
        <HashLoader css={override} size={140} color={"white"} />
      )}
    </>
  );
};

export default Blog;
