import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  typography: {
    "&:hover": {
      color: "#FF8C00",
    },
  },
  navBar: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    marginLeft: "auto",
  },
  link: {
    textDecoration: "none",
    color: "inherit",
    "&:hover": {
      color: "#FF8C00",
    },
  },
}));

function Navbar(props) {
  const classes = useStyles();
  return (
    <List className={classes.navBar} component="nav">
      <ListItem component="div">
        <ListItemText inset>
          <Link className={classes.link} to="/about">
            ABOUT
          </Link>
        </ListItemText>
        <ListItemText inset>
          <Link className={classes.link} to="/blog">
            BLOGS
          </Link>
        </ListItemText>
        <ListItemText inset>
          <Link className={classes.link} to="/resume">
            RESUME
          </Link>
        </ListItemText>
        <ListItemText inset>
          <Link className={classes.link} to="/portfolio">
            PORTFOLIO
          </Link>
        </ListItemText>
      </ListItem>
    </List>
  );
}

export default Navbar;
