import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { AiOutlineArrowRight } from "react-icons/ai";

import resume from "../assets/pdfs/Suraj_Resume.pdf"
import wall from "../assets/images/wall.png";
import Proposition from "../components/UI/Proposition/Proposition";
import { Button } from "../components/UI/Button/Button";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  proposition: {
    marginTop: 40,
    marginBottom: 40,
    fontSize: "1.7em",
    fontWeight: 600,
    opacity: "0.95",
    alignItems: "center",
    color: "#ffa400",
  },
  button: {
    position: "fixed",
    top: 400,
    margin: "40px auto",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      left: 35,
    },
  },
  animation: {
    marginLeft: 40,
    marginTop: 0,
  },
  media: {
    marginTop: 0,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  me: {
    height: "auto",
    width: 660,
    [theme.breakpoints.down("md")]: {
      height: "auto",
      width: 460,
      position: "relative",
    },
  },
}));
export default function Home() {
  const classes = useStyles();

  return (
    /* Home page container */
    <Grid container>
      {/* Heading */}
      <Grid item xs={12} md={6}>
        <Typography variant="h5" gutterBottom className={classes.proposition}>
          <span role="img" aria-label="Hi-emoji">
            👋
          </span>
          &nbsp;Hi! How can I help you ?
        </Typography>

        <Grid className={classes.animation}>
          <Proposition />
        </Grid>
      </Grid>

      {/*Media Transition */}
      <Grid item xs={12} md={6} className={classes.media}>
        <img src={wall} alt="Wallpaper" className={classes.me} />
      </Grid>
      {/* button container */}
      <Grid className={classes.button}>
        {/* Arrow Icon */}
        <Grid item>
          <AiOutlineArrowRight
            fontSize="large"
            style={{ color: "#ffa400", marginRight: 20, marginTop: 12 }}
          />
        </Grid>

        {/*contact button */}
        <Grid item>
          <Link to="/contact">
            <Button>CONTACT</Button>
          </Link>
        </Grid>

        {/*resume*/}
        <Grid item>
          <a href={resume} target="blank" download>
            <Button active>DOWNLOAD</Button>
          </a>
        </Grid>
      </Grid>
    </Grid>
  );
}
