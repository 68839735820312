import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function CustomModal(props) {
  const classes = useStyles();
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={props.modalIsOpen}
      onClose={props.handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 400,
      }}
    >
      <Fade in={props.modalIsOpen}>
        <div className={classes.paper}>
          <h2 id="transition-modal-title" style={{ fontSize: "20px" }}>
            YOUR MESSAGE WAS SENT!
          </h2>
          <p id="transition-modal-description" style={{ fontSize: "16px" }}>
            {props.data} I'll be in touch with you soon.
          </p>
        </div>
      </Fade>
    </Modal>
  );
}
