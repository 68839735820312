import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import Title from "../components/UI/Title/Title";
import Profile from "../components/Profile/Profile";
import Achievement from "../components/Achievement/Achievements";
import { skills, profile, achievements, publications } from "../data";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    flexGrow: 1,
    marginBottom: 0,
  },
  title: {
    textAlign: "center",
    marginBottom: 10,
    fontSize: 18,
    color: "#ffa400",
  },
  sectionTitle: {
    textAlign: "center",
    fontSize: 20,
    fontWeight: "bold",
    color: "#ffa400",
    marginBottom: 10,
  },
  skillRow: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 30,
  },
  skillTitle: {
    fontWeight: "bold",
    fontSize: 18,
    marginRight: 23,
    color: "#009ffa",
  },
  skillValue: {
    flexGrow: 1,
    textAlign: "justify",
  },
  publicationRow: {
    marginBottom: 10,
    paddingBottom: 10,
  },
  publicationTitle: {
    fontWeight: "bold",
    textDecoration: "none",
    color: "white",
    '&:hover': {
      color: "#ff7400",
    },
  },
  publicationJournal: {
    fontStyle: "italic",
    color: "#009ffa",
    fontSize: 14
  },
  link: {
    textDecoration: "none",
    color: "inherit",
  },
});

function About() {
  const classes = useStyles();
  return (
    <Grid container>
      <Grid item xs={12}>
        <Title title="Dig A Little Deeper" />
      </Grid>

      <Grid container spacing={7} className={classes.root}>
        <Grid item xs={12} md={6} style={{ marginTop: "16px" }}>
          <Profile width={38} height={38} profile={profile} />
        </Grid>
        <Grid item xs={12} md={6} style={{ marginTop: "-10px" }}>
          <Typography className={classes.sectionTitle}>SKILLS</Typography>
          {skills.map((skill) => (
            <Grid item xs={12} key={skill.title} className={classes.skillRow}>
              <Typography className={classes.skillTitle}>{skill.title}:</Typography>
              <Typography className={classes.skillValue}>{skill.value}</Typography>
            </Grid>
          ))}
          <Typography className={classes.sectionTitle}>PUBLICATIONS</Typography>
          {publications.map((publication) => (
            <Grid item xs={12} key={publication.title} className={classes.publicationRow}>
              <Typography className={classes.publicationTitle}>
                <a
                  href={publication.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.link}
                >
                  {publication.title}
                </a>
              </Typography>
              <Typography className={classes.publicationJournal}>
                {publication.publication}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Typography className={classes.sectionTitle}>
          ACHIEVEMENTS
        </Typography>
      </Grid>
      <Grid container spacing={2} className={classes.root}>
        {achievements.map((a) => (
          <Grid item xs={12} sm={6} key={a.title}>
            <Achievement title={a.title} link={a.link} />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}

export default About;
