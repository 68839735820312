import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { SiGithub } from "react-icons/si";
import { AiOutlineLink } from "react-icons/ai";

import Link from "@material-ui/core/Link";

const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
    alignContent: "space-between",
    backgroundColor: "#1a1a1a",
  },
  caps: {
    fontVariant: "all-small-caps",
    color: "#FF7E00",
    fontSize: 17,
  },
});

const ProjectCard = (props) => {
  const classes = useStyles();
  const { image, title, description, websiteURL, githubURL, technology } =
    props.project;
  const img = image;

  return (
    <Card className={classes.root}>
      <CardActionArea>
        <Link
          href={websiteURL ? websiteURL : githubURL}
          rel="noopener noreferrer"
          target="_blank"
        >
          <CardMedia
            component="img"
            alt={title}
            image={img}
            title={title}
            height="240px"
            width="auto"
            style={{ color: "white" }}
          />
        </Link>
      </CardActionArea>
      <CardContent>
        <Typography
          gutterBottom
          variant="h5"
          style={{ color: "#ffa400", fontWeight: "bold" }}
          component="h2"
        >
          {title}
        </Typography>
        <Typography
          variant="body1"
          style={{ color: "white", fontSize: 15 }}
          component="p"
        >
          {description}
        </Typography>
        <Typography
          variant="h6"
          color="textSecondary"
          component="p"
          className={classes.caps}
        >
          {technology}
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          variant="contained"
          size="small"
          color="default"
          href={githubURL}
          rel="noopener noreferrer"
          target="_blank"
          aria-label="github"
          fullWidth={false}
        >
          <SiGithub size={30} />
        </Button>
        {websiteURL ? (
          <Button
            variant="contained"
            size="small"
            color="default"
            href={websiteURL}
            rel="noopener noreferrer"
            target="_blank"
            aria-label="visit-external-website"
          >
            <AiOutlineLink size={30} />
          </Button>
        ) : null}
      </CardActions>
    </Card>
  );
};

export default ProjectCard;
