import styled from "styled-components";

export const Button = styled.button`
  width: 105px;
  height: 40px;
  text-transform: capitalize;
  font-size: 0.9rem;
  margin: 25 px;
  outline: none !important;
  background: transparent;
  background-color: ${(props) => (props.active ? "#ffa400" : "black")};
  border-color: ${(props) => (props.active ? "#ffa400" : "white")};
  color: ${(props) => (props.active ? "black" : "white")};
  border-radius: 0.5rem;
  padding: 0.5rem 0.5rem;
  cursor: pointer;
  margin: 0.2rem 1.6rem 0.2rem 0;
  transition: all 0.5s ease-in-out;
  &:hover {
    background: ${(props) => (props.active ? "black" : "black")};
    color: ${(props) => (props.active ? "#ffa400" : "#38eeff")};
    border: ${(props) =>
      props.active ? "0.1rem solid #ffa400" : "0.01rem solid #38eeff"};
  }
  &:focus {
    outline: none;
  }
`;
