import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";

import AppBar from "./components/Navigation/AppBar";
import "./App.css";

const theme = createTheme({
  palette: {
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#003049",
    },
  },
});
function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <div className="container">
        <CssBaseline />
        <Container>
          <AppBar />
        </Container>
      </div>
    </MuiThemeProvider>
  );
}

export default App;
