import React from "react";
import Avatar from "@material-ui/core/Avatar";
import { Card, CardContent, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import pic from "../../assets/images/user.jpg";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
    alignContent: "space-between",
    backgroundColor: "black",
    //marginTop: 10,
  },
  large: (props) => {
    return {
      width: theme.spacing(props.width),
      height: theme.spacing(props.height),
      borderRadius: 15,
    };
  },
  title: {
    fontSize: "26px",
    color: "#ffa400",
    textAlign: "center",
    fontWeight: "bold",
    marginBottom: 20,
    marginTop: 0,
  },
  info: {
    fontSize: "16px",
    color: "#BFBFBF",
    textAlign: "center",
  },
}));

function Profile(props) {
  const classes = useStyles(props);
  const { desc } = props.profile;

  return (
    <Card className={classes.root} elevation={0}>
      <Avatar
        className={classes.large}
        alt="Suraj Karki"
        variant="square"
        src={pic}
      />
      <CardContent>
        <Typography gutterBottom className={classes.title}>
          Who's this guy ?
        </Typography>
        <Typography className={classes.info}>{desc}</Typography>
      </CardContent>
    </Card>
  );
}
export default Profile;
