import React from "react";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  title: {
    color: "#009ffa",
    fontSize: 12,
  },
  titleBorder: {
    color: "#009ffa",
    margin: 10,
    padding: 5,
    border: "0px solid #ffa400",
    borderLeftWidth: "0.4em",
  },
}));

export default function Title({ title }) {
  const classes = useStyles();
  return (
    <div className={classes.title}>
      <h1>
        <span className={classes.titleBorder}>{title}</span>
      </h1>
    </div>
  );
}
