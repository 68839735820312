import React, { useRef, useState, useEffect, useCallback } from "react";
import { useTransition, animated } from "react-spring";

const Preposition = React.memo(() => {
  const ref = useRef([]);
  const [items, set] = useState([]);
  const transitions = useTransition(items, null, {
    from: {
      opacity: 0,
      height: 0,
      innerHeight: 0,
      transform: "perspective(500px) rotateX(0deg)",
      color: "#8fa5b6",
    },
    enter: [
      { opacity: 1, height: 40, innerHeight: 30 },
      { transform: "perspective(500px) rotateX(180deg)", color: "#28d79f" },
      { transform: "perspective(500px) rotateX(0deg)" },
    ],
    leave: [
      { color: "#c23369" },
      { innerHeight: 0 },
      { opacity: 0, height: 0 },
    ],
    update: { color: "#28b4d7" },
    delay: 250,
  });

  const reset = useCallback(() => {
    ref.current.map(clearTimeout);
    ref.current = [];
    set([]);
    const t1 = setTimeout(
      () => set(["A.I","Software", "Blockchain"]),
      300
    );
    ref.current.push(t1);
    const t2 = setTimeout(
      () => set(["Software Dev.", "Fast Learner", "AI Enthusiast"]),
      4000
    );
    ref.current.push(t2);
    const t3 = setTimeout(
      () =>
        set([
          "Fast Learner",
          "Software Dev",
          "AI Practitioner",
          "Research Enthusiast"
        ]),
      7000
    );
    ref.current.push(t3);
    return { t1, t2, t3 };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const { t1, t2, t3 } = reset();
    return function cleanup() {
      clearTimeout(t1);
      clearTimeout(t2);
      clearTimeout(t3);
    };
  }, [reset]);

  return (
    <div>
      {transitions.map(({ item, props: { innerHeight, ...rest }, key }) => (
        <animated.div className="transitions-item" key={key} style={rest}>
          <animated.div style={{ height: innerHeight }}>{item}</animated.div>
        </animated.div>
      ))}
    </div>
  );
});

export default Preposition;
