const GET_USER_ARTICLES = `
query Publication {
  publication(host: "savvysuraj.hashnode.dev") {
    posts(first: 20) {
      edges {
        node {
          title
          brief
          slug
          publishedAt
          coverImage{
            url
          }
        }
      }
    }
  }
}
`;

export { GET_USER_ARTICLES };
