import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

import { projects } from "../data";
import Title from "../components/UI/Title/Title";
import ProjectCard from "../components/ProjectCard/ProjectCard";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    flexGrow: 1,
    marginBottom: 0,
    marginTop: 0,
  },
});

function Portfolio() {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item>
        <Title title="Projects" />
      </Grid>
      <Grid container spacing={3} className={classes.root}>
        {projects.map((project) => (
          <Grid item sm={4} key={projects.indexOf(project)}>
            <ProjectCard project={project} />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}

export default Portfolio;
