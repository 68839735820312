import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";

import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
    alignContent: "space-between",
    backgroundColor: "#1a1a1a",
  },
  caps: {
    fontVariant: "all-small-caps",
    color: "#FF7E00",
    fontSize: 17,
  },
});

const BlogCard = (props) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <div className="content-container">
        <div className="image-container">
          <img
            src={props.blog.node.coverImage.url}
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
            alt="Cover"
          />
        </div>
        <CardContent>
          <Typography
            gutterBottom
            variant="h5"
            style={{ color: "#ffa400", fontWeight: "bold" }}
            component="h2"
          >
            {props.blog.node.title}
          </Typography>
          <Typography
            variant="caption"
            style={{
              color: "white",
              fontSize: 15,
              textAlign: "justify",
              fontWeight: "bold",
              fontStyle: "italic",
            }}
            component="p"
          >
            {new Date(props.blog.node.publishedAt).toLocaleDateString()}
          </Typography>

          {
            <Typography
              variant="body1"
              style={{ color: "white", fontSize: 15, textAlign: "justify" }}
              component="p"
            >
              {" "}
              <br />
              {props.blog.node.brief}
            </Typography>
          }
          {
            <a
              style={{ textDecoration: "none", color: "#ffa400" }}
              href={`https://savvysuraj.hashnode.dev/${props.blog.node.slug}`}
              rel="noopener noreferrer"
              target="_blank"
            >
              Read more...
            </a>
          }
        </CardContent>
      </div>

      <style jsx>{`
        @media (max-width: 600px) {
          /* Adjust the layout for small screens */
          .content-container {
            display: flex;
            flex-direction: column;
          }
          .image-container {
            order: -1;
          }
        }
        @media (min-width: 601px) {
          /* Adjust the layout for larger screens */
          .content-container {
            display: grid;
            grid-template-columns: 1fr 2fr;
          }
          .image-container {
            order: -1;
          }
        }
      `}</style>
    </Card>
  );
};

export default BlogCard;
