import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexWrap: "wrap",
    backgroundColor: "#1a1a1a",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
}));

export default function Achievement(props) {
  const classes = useStyles();

  return (
    <Card className={classes.root} elevation={1}>
      <div className={classes.details}>
        <CardContent>
          <Typography
            style={{ color: "white", fontSize: "15px", fontWeight: "bold" }}
          >
            {props.title}
          </Typography>
          <a
            href={props.link}
            target="blank"
            style={{ textDecoration: "none" }}
          >
            <Typography
              variant="subtitle1"
              style={{
                color: "#ffa400",
                fontWeight: "bold",
                cursor: "pointer",
                fontSize: "14px",
              }}
            >
              Show Details
            </Typography>
          </a>
        </CardContent>
      </div>
    </Card>
  );
}
