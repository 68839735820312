import React from "react";
import { TextField } from "@material-ui/core";

export default function Input(props) {
  const {
    name,
    label,
    value,
    error = null,
    onChange,
    required,
    multiline,
    rows,
  } = props;
  return (
    <TextField
      variant="filled"
      label={label}
      name={name}
      value={value}
      onChange={onChange}
      {...(error && { error: true, helperText: error })}
      required={required}
      multiline={multiline}
      rows={rows}
    />
  );
}
