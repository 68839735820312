import configs from "./config";

import MediLeaf from "./assets/images/medileaf.png";
import Lucy from "./assets/images/lucy.png";
import Glasir from "./assets/images/glasir.png";
import DD from "./assets/images/dd_detection.png";
import PBS from "./assets/images/myblog.jpg";
import ShortIT from "./assets/images/shortit6.png";
import MovieDB from "./assets/images/movie-db.jpg";
import BKing from "./assets/images/burgerking.jpg";
import Rpass from "./assets/images/rpass.png";
import Ptop from "./assets/images/PTop.png";
import IC from "./assets/images/imagecompressor.png";
import cryptodex from "./assets/images/cryptodex.png";
import KProject from "./assets/images/killer-project.jpg";
import CManager from "./assets/images/contact-manager.jpg";
import Freyja from "./assets/images/freyja.png";
import ConnectCam from "./assets/images/connect-cam.png";
import ChitChat from "./assets/images/chitchat.png";
import SmartScanner from "./assets/images/smart_scanner.png";
import Omdena_SriLanka from "./assets/images/Omdena_Sri_Lanka.png";

const publications = [
  {
    title:
      "Bridging the Gap in College Information Access Through Natural Language Processing Powered Lucy",
    publication:
      "BOHR International Journal of Smart Computing and Information Technology",
    link: "https://doi.org/10.54646/bijscit.2023.39",
  },
  {
    title:
      "Strict Left Bundle Branch Block Diagnose Through Explainable Artificial Intelligence",
    publication:
      "IWINAC 2024, Lecture Notes in Computer Science, vol 14675. Springer, Cham",
    link: "https://doi.org/10.1007/978-3-031-61137-7_47",
  },
];
const skills = [
  {
    title: "Languages",
    value: "Python, JavaScript, TypeScript, Java, Dart, Bash, C/C+",
  },
  {
    title: "Tools",
    value:
      "TensorFlow, PyTorch, Pandas, Numpy, Scikit-learn, NLTK, Spacy, OpenCV, LangChain, Streamlit, BentoML, Microsoft Azure, AWS, React.js, Django, Flask, FastAPI, Express.js, Node.js, Socket.io, MongoDB, PostgreSQL, Linux, Jenkins, Docker, Kubernetes, Git",
  },
  {
    title: "Concepts",
    value:
      "Machine Learning, Deep Learning, Computer Vision, NLP, LLM, Stable Diffusion, GAN, Web Development, API, WebSocket, WebRTC, Testing, Agile Methodology, Cloud Computing",
  },
];

const profile = {
  emails:
    "suraj.karki500@gmail.com suraj.karki500@protonmail.com suraj.karki600@protonmail.com",
  desc: "I am a graduate computer engineer with a good understanding and hands-on experience in software development, artificial intelligence, and computer networks. Artificial intelligence, deep learning, and software development are my core strengths. I have some expertise in computer vision and generative AI and want to work on and improve myself in these domains in the future. I am looking for opportunities and collaborations in data science, deep learning, and software development projects.",
};

const achievements = [
  {
    title:
      "Awarded a scholarship for the Erasmus+ KA1 Mobility Program at Universidad Politˊecnica de Cartagena, Spain, from September 2023 to February 2024",
    link: "https://www.upct.es/",
  },
  {
    title: "Completed Free OpenCV Boot-camp from OpenCV University.",
    link: "https://courses.opencv.org/certificates/1ba2a781d8a74bc0af363a49b9a548dd",
  },

  {
    title: "Completed Deep Learning Specialization By Coursera.",
    link: "https://www.coursera.org/account/accomplishments/specialization/certificate/CV6CHH362FJR",
  },
  {
    title:
      "Completed Natural Language Preprocessing Specialization By Coursera.",
    link: "https://www.coursera.org/account/accomplishments/specialization/certificate/WFLHX8XB48V3",
  },
  {
    title: "Education Category Winner Of Quantum Hackathon 2020.",
    link: `${configs.BASE_URL}uploads/QH_HackathonWinner-Freyja-Suraj.pdf`,
  },
  {
    title: "Completed MongoDB For Javascript By MongoDB University.",
    link: "https://university.mongodb.com/course_completion/734e8f1b-9b9d-4db9-a887-ea210b8f56b0?utm_source=copy&utm_medium=social&utm_campaign=university_social_sharing",
  },
  {
    title: "Completed MongoDB Basics By MongoDB University.",
    link: "https://university.mongodb.com/course_completion/07af40e8-cfd9-4136-959a-32fa9c9db364?utm_source=copy&utm_medium=social&utm_campaign=university_social_sharing",
  },
  {
    title:
      "Completed MongoDB Cluster Administration Basics By MongoDB University.",
    link: "https://university.mongodb.com/course_completion/6768da50-7824-4475-8cb8-272ba9195461?utm_source=copy&utm_medium=social&utm_campaign=university_social_sharing",
  },
];

const projects = [
  {
    title: "Predicting Dengue Fever Outbreaks in Sri Lanka",
    image: Omdena_SriLanka,
    description: "As part of an Omdena project, I contributed to developing an AI-driven system to predict dengue fever outbreaks in Sri Lanka. By applying time series analysis and machine learning techniques, the project aimed to enhance early warning capabilities, enabling more efficient public health responses and potentially saving lives.",
    technology:
      "Python • Pandas • Scikit-learn • Darts • Streamlit",
    githubURL: "https://github.com/surajkarki66/Omdena_Sri_Lanka_Predicting_Dengue_Fever_Outbreak",
    websiteURL: "https://omdenasrilankapredictingdenguefeveroutbreak-yavgcdwul8pdb8ufkf.streamlit.app/"
    
  },
  {
    title: "Smart Document Scanner",
    image: SmartScanner,
    description:
      "This is a document scanner web application along with the feature of optical character recognition aka. image to text conversion, built using Flask.",
    technology:
      "Flask • OpenCV • Tesseract OCR • HTML • CSS • JavaScript",
    githubURL: "https://github.com/surajkarki66/smart-document-scanner",
  },
  {
    title: "ConnectCam",
    image: ConnectCam,
    description:
      "ConnectCam is a full stack web application that is built for the purpose of video conferencing. It is a simple and easy to use application that allows users to create a room and share the room link with others to join the room. It is built using WebRTC and Socket.io for real-time communication.",
    technology:
      "Next.js • Node.js • Express.js • WebRTC • Socket.io • TailwindCSS",
    websiteURL: "https://connect-cam-app.vercel.app/",
    githubURL: "https://github.com/surajkarki66/ConnectCam-app",
  },
  {
    title: "Chitchat",
    image: ChitChat,
    description:
      "Chitchat is a real-time group chat web application built using HTML, CSS, JavaScript and Socket.io. This facilitates user to create room or join the existing room, where they can communicate with other users using messages.",
    technology:
      "HTML • CSS • JavaScript • Express.js • Socket.io • Node.js • Redis",
    websiteURL: "https://chitchat-0zpv.onrender.com",
    githubURL: "https://github.com/surajkarki66/real-time-groupchat-app",
  },
  {
    title: "MediLeaf",
    image: MediLeaf,
    description:
      "MediLeaf is an application whose motive is to help the individual to identify medicinal plant with their properties by just scanning the leaf of any plant which might result creating curiosity about plant that lead to the preservation of the valuable plants as well as source of income. ",
    technology: "Flutter • Tensorflow • Django • PostgreSQL",
    githubURL: "https://github.com/surajkarki66/MediLeaf_Mobile",
  },
  {
    title: "Lucy",
    image: Lucy,
    description:
      "Lucy is an NLP-Powered chatbot that can interact with humans, and give the answer to the question which is related to Nepal Engineering College.",
    technology: "React.js • MaterialUI • FastAPI • PostgreSQL • PyTorch",
    githubURL: "https://github.com/surajkarki66/Lucy-Backend.git",
  },
  {
    title: "Glasir",
    image: Glasir,
    description:
      "Glasir is a Nepali freelancing platform. Here you can find jobs and also applied for job as freelancer. As employer you can also hire freelancer for your jobs.",
    technology: "React.js • MaterialUI • Express.js • MongoDB • Node.js",
    githubURL: "https://github.com/surajkarki66/glasir",
  },
  {
    title: "Freyja",
    image: Freyja,
    description:
      "This is a web app to grade essay using LSTM model using Django REST, React and Tensorflow. The dataset used to train the NLP model is https://www.kaggle.com/mpwolke/hewlett-foundation-essay-scoring , this project was the education category winner of Quantum Hack 2020.",
    technology: "Django • Python • Tensorflow • Spacy • NLP • Docker",
    githubURL: "https://github.com/surajkarki66/Freyja",
  },
  {
    title: "Driver Drowsiness Detection",
    image: DD,
    description:
      "This is an end-to-end deep learning powered application that detects whether the driver who is driving the vehicle is sleeping or not sleeping. ",
    technology: "Django • Python • Tensorflow • OpenCV • Deep Learning",
    githubURL: "https://github.com/surajkarki66/driver_drowsiness_detection",
  },
  {
    title: "Blogging Site",
    image: PBS,
    websiteURL: "https://personal-blog-site-ten.vercel.app/",
    description:
      "This is simple website for blogging, but only creator can create the blog. Other people can see and comments on those blogs.",
    technology: "Django • HTML • Bootstrap CSS • Javascript • JQuery",
    githubURL: "https://github.com/surajkarki66/personal-blog-site.git",
  },
  {
    title: "Short IT",
    image: ShortIT,
    websiteURL: "https://shortit-zme7.onrender.com/",
    description:
      "This is the website that is simple and fast to short your freaking long url. It's primary focus is on user's privacy. It is an open source website.",
    technology: "React.js • Antd • Node.js • Express.js • MongoDB",
    githubURL: "https://github.com/surajkarki66/shortIT",
  },
  {
    title: "Movie DB",
    image: MovieDB,
    websiteURL: "https://moviedb6.netlify.app/",
    description:
      "This is the website looks like IMDB, Which is the web app for movie lovers. It shows all lists and details of movies. You can also make the movie favourite and can comment on movie.",
    technology: "React.js • Redux • Node.js • Express.js • MongoDB • Movie API",
    githubURL: "https://github.com/surajkarki66/moviedb",
  },
  {
    title: "Burger King",
    image: BKing,
    websiteURL: "https://burger-king.vercel.app/",
    description:
      "This the single page web application. From this web app you can create your own burger and order the burger.",
    technology: "React.js • Javascript • Node.js • Firebase • CSS",
    githubURL: "https://github.com/surajkarki66/burger-king.git",
  },
  {
    title: "Rpass",
    image: Rpass,
    description:
      "A CLI based application built with Python for generating strong random passwords. It also shows all the passwords you generated. ",
    technology: "Python • CLI • Click • Random • Password",
    githubURL: "https://github.com/surajkarki66/rpass",
  },
  {
    title: "PTop",
    image: Ptop,
    description:
      "This is a cross-platform desktop application to check RAM and CPU usage of your system and monitor your system.This is built with help of electron.js framework.",
    technology: "Electron • HTML • CSS • Javascript • Node.js",
    githubURL: "https://github.com/surajkarki66/PTop",
  },
  {
    title: "ImageCompressor",
    image: IC,
    description:
      "This is a cross platform desktop application to shrink the size of image. You can select large sized image and compress the image and saved it in your system.",
    technology: "Electron • HTML • CSS • Javascript • Node.js",
    githubURL: "https://github.com/surajkarki66/image-compressor",
  },
  {
    title: "Cryptodex",
    image: cryptodex,
    description:
      "A CLI based application built with Typescript to check the pricing of crypto coins. You can install this using npm or yarn.",
    technology: "Node.js • Commander • Chalk • Javascript • Inquirer",
    githubURL: "https://github.com/surajkarki66/cryptodex",
  },
  {
    title: "The Killer Project",
    image: KProject,
    description:
      "This is just simple project which gives you details about an app called OMNI Food.",
    technology: "HTML5 • CSS3 • Javascript • JQuery • Bootstrap5",
    githubURL: "https://github.com/surajkarki66/The-killer-Project",
  },
  {
    title: "Contact Manager",
    image: CManager,
    description:
      "This is gui application built in Python.This application manages your contact information. You can create contacts, delete contacts and update contacts.",
    technology: "Python • Tkinter • SQlite • ",
    githubURL: "https://github.com/surajkarki66/contact-manager",
  },
];

export { skills, profile, achievements, projects, publications };
