import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  large: (props) => {
    return {
      width: theme.spacing(props.size),
      height: theme.spacing(props.size),
    };
  },
}));

export default function ImageAvatars(props) {
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <Avatar alt="Suraj" src={props.logo} className={classes.large} variant = "rounded" />
    </div>
  );
}
