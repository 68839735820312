import React, { Fragment, useState } from "react";
import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import { AiOutlineMenu } from "react-icons/ai";

import Avatar from "@material-ui/core/Avatar";
import { Router, Route, Link, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";

// Pages
import Home from "../../pages/Home";
import Portfolio from "../../pages/Portfolio";
import About from "../../pages/About";
import Resume from "../../pages/Resume";
import Contact from "../../pages/Contact";
import Blog from "../../pages/Blog";

// components

import Navbar from "./Navbar/NavBar";
import { Button } from "../UI/Button/Button";
import ImageAvatar from "../UI/ImageAvatar/ImageAvatar";
import logo from "../../assets/images/logo2.png";
import user from "../../assets/images/user.jpg";
import Footer from "../UI/Footer/Footer";

const history = createBrowserHistory();

const drawerWidth = 150;
const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  flex: {
    flex: 1,
    color: "white",
    fontSize: "1.6em",
    fontWeight: 600,
    marginLeft: 30,
    "&:hover": {
      color: "#FF8C00",
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.4rem",
    },
  },
  drawerPaper: {
    position: "relative",
    width: drawerWidth,
    backgroundColor: "#1a1a1a",
    textAlign: "center",
  },
  menuButton: {
    marginLeft: 10,
    marginRight: 10,
    color: "white",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  toolbarMargin: theme.mixins.toolbar,
  aboveDrawer: {
    zIndex: theme.zIndex.drawer + 1,
    borderBottom: "1px solid white",
  },
  item: {
    color: "white",
    fontWeight: 600,
    "&:hover": {
      color: "#FF8C00",
    },
    fontSize: 15,
    marginBottom: 10,
  },
  footer: {
    position: "fixed",
    bottom: "0px",
    marginBottom: 4,
    marginLeft: 10,
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      bottom: "0px",
      marginBottom: 10,
      marginLeft: 0,
    },
  },
  social: {
    marginBottom: 10,
    [theme.breakpoints.down("sm")]: {
      margin: "10px auto",
      marginTop: 0,
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
});
const MyToolbar = withStyles(styles)(({ classes, onMenuClick }) => {
  return (
    <Fragment>
      <AppBar className={classes.aboveDrawer} color="primary" elevation={0}>
        <Toolbar style={{ height: 0 }}>
          <IconButton
            className={classes.menuButton}
            aria-label="Menu"
            onClick={onMenuClick}
          >
            <AiOutlineMenu size={30} color="#FFFFF" />
          </IconButton>
          <ImageAvatar size={6.5} logo={logo} />
          <a href="/" style={{ textDecoration: "none", color: "white" }}>
            <Typography variant="h5" className={classes.flex}>
              SURAJ KARKI
            </Typography>
          </a>

          <Navbar />
        </Toolbar>
      </AppBar>
      <div className={classes.toolbarMargin} />
    </Fragment>
  );
});

const MyDrawer = withStyles(styles)(({ classes, variant, open, onClose }) => (
  <React.Fragment>
    <Drawer
      elevation={20}
      variant={variant}
      open={open}
      onClose={onClose}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div
        className={clsx({
          [classes.toolbarMargin]: variant === "persistent",
        })}
      />
      <List>
        <ListItem button component={Link} to="/" onClick={onClose}>
          <Typography className={classes.item} style={{ marginLeft: 35 }}>
            HOME
          </Typography>
        </ListItem>
        <ListItem button component={Link} to="/blog" onClick={onClose}>
          <Typography className={classes.item} style={{ marginLeft: 36 }}>
            BLOG
          </Typography>
        </ListItem>
        <ListItem button component={Link} to="/about" onClick={onClose}>
          <Typography className={classes.item} style={{ marginLeft: 30 }}>
            ABOUT
          </Typography>
        </ListItem>
        <ListItem button component={Link} to="/resume" onClick={onClose}>
          <Typography className={classes.item} style={{ marginLeft: 25 }}>
            RESUME
          </Typography>
        </ListItem>
        <ListItem button component={Link} to="/portfolio" onClick={onClose}>
          <Typography className={classes.item} style={{ marginLeft: 15 }}>
            PORTFOLIO
          </Typography>
        </ListItem>
        <ListItem button component={Link} to="/contact" onClick={onClose}>
          <Button active style={{ marginLeft: 17 }}>
            CONTACT
          </Button>
        </ListItem>
      </List>

      {/* Footer */}
      <div className={classes.footer}>
        <Divider className={classes.divider} />
        <Avatar
          src={user}
          alt="SurajKarki"
          style={{
            margin: "5px auto",
            textAlign: "center",
            display: "block",
          }}
        />
        <Footer />
      </div>
    </Drawer>
    <main className={classes.content}>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/portfolio" component={Portfolio} />
        <Route exact path="/about" component={About} />
        <Route exact path="/resume" component={Resume} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/blog" component={Blog} />
      </Switch>
    </main>
  </React.Fragment>
));

function AppBarInteraction({ classes, variant }) {
  const [drawer, setDrawer] = useState(false);
  const [title, setTitle] = useState("Home");

  const toggleDrawer = () => {
    setDrawer(!drawer);
  };

  const onItemClick = (title) => () => {
    setTitle(title);
    setDrawer(variant === "temporary" ? false : drawer);
    setDrawer(!drawer);
  };
  return (
    <div className={classes.root}>
      <Router history={history}>
        <MyToolbar title={title} onMenuClick={toggleDrawer} />
        <MyDrawer
          open={drawer}
          onClose={toggleDrawer}
          onItemClick={onItemClick}
          variant={variant}
        />
      </Router>
    </div>
  );
}

export default withStyles(styles)(AppBarInteraction);
