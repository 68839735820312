import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

export default function Footer() {
  return (
    <Grid>
      <Typography
        variant="body1"
        style={{ color: "white", fontSize: "0.8rem" }}
        align="center"
      >
        Created by Suraj Karki
      </Typography>
      <Typography
        variant="body1"
        style={{ color: "white", fontSize: "0.8rem" }}
        align="center"
      >
        {"Copyright © "} {new Date().getFullYear()}
        {"."}
      </Typography>
    </Grid>
  );
}
